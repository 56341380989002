import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OffCanvasMenu from "./OffCanvasMenu";

const Navbar = ({ navDark }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".main-header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header className={`main-header ${navDark ? "position-absolute" : ""} w-100 `}>
        <nav
          className={`navbar navbar-expand-xl ${
            navDark ? "navbar-dark" : "navbar-light"
          } sticky-header ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <Link
              to={process.env.PUBLIC_URL + "/"}
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img
                src="assets/img/logo.png"
                alt="logo"
                className="img-fluid logo-white"
                style={{ height: "28px" }}
              />
              <img
                src="assets/img/logo-colorized.png"
                style={{ width: "130px" }}
                alt="logo"
                className="img-fluid logo-color"
              />
            </Link>

            <Link
              className="navbar-toggler position-absolute right-0 border-0"
              to={process.env.PUBLIC_URL + "#offcanvasWithBackdrop"}
              role="button"
            >
              <span
                className="far fa-bars"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBackdrop"
                aria-controls="offcanvasWithBackdrop"
              ></span>
            </Link>

            <div className="collapse navbar-collapse justify-content-center">
              <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                <li>
                  <a href={process.env.PUBLIC_URL + "/#home"} className="nav-link">
                    Home
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/#about"} className="nav-link">
                    About
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/#roadmap"} className="nav-link">
                    Roadmap
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/#faq"} className="nav-link">
                    Faq
                  </a>
                </li>
                <li>
                  <a href={process.env.PUBLIC_URL + "/#tokenomics"} className="nav-link">
                    Tokenomics
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/valid-proof/audit/blob/main/ValidProof_SmartContract_Audit_Metaversepay.pdf"
                    target="_blank"
                    className="nav-link"
                  >
                    Audit
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/valid-proof/kyc/blob/main/ValidProof_KYC_Certificate_Metaversepay.pdf"
                    target="_blank"
                    className="nav-link"
                  >
                    Kyc
                  </a>
                </li>

                <li>
                  <a href="/whitepaper.pdf" target="_blank" className="nav-link">
                    Whitepaper
                  </a>
                </li>
              </ul>
            </div>

            <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
              <a
                href="https://poocoin.app/tokens/0xbba18524ca3c6aa26306e5c402c0fed72e62a6c3"
                className="btn me-3"
                style={{ background: "#002B84", color: "white" }}
                target="_blank"
              >
                <i class="fas fa-chart-line"></i>
                <span style={{ marginLeft: "5px" }}>Chart</span>
              </a>
            </div>
            <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
              <a
                href="https://pancakeswap.finance/swap?outputCurrency=0xbba18524CA3c6AA26306E5C402C0FEd72E62A6C3"
                className="btn btn-primary"
                target="_blank"
              >
                Buy Now
              </a>
            </div>
            <OffCanvasMenu />
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
