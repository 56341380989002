import React from "react";
import { Link } from "react-router-dom";

const OffCanvasMenu = () => {
  return (
    <>
      <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasWithBackdrop">
        <div className="offcanvas-header d-flex align-items-center mt-4">
          <Link to="/" className="d-flex align-items-center mb-md-0 text-decoration-none">
            <img
              src="assets/img/logo-colorized.png"
              style={{ width: "140px" }}
              alt="logo"
              className="img-fluid ps-2"
            />
          </Link>
          <button
            type="button"
            className="close-btn text-danger"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="far fa-close"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <ul className="nav col-12 col-md-auto justify-content-center main-menu">
            <li>
              <a href={process.env.PUBLIC_URL + "/#home"} className="nav-link">
                Home
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/#about"} className="nav-link">
                About
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/#roadmap"} className="nav-link">
                Roadmap
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/#faq"} className="nav-link">
                Faq
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/#tokenomics"} className="nav-link">
                Tokenomics
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/#howtobuy"} className="nav-link">
                How To Buy?
              </a>
            </li>
            <li>
              <a
                href="https://github.com/valid-proof/audit/blob/main/ValidProof_SmartContract_Audit_Metaversepay.pdf"
                target="_blank"
                className="nav-link"
              >
                Audit
              </a>
            </li>
            <li>
              <a
                href="https://github.com/valid-proof/kyc/blob/main/ValidProof_KYC_Certificate_Metaversepay.pdf"
                target="_blank"
                className="nav-link"
              >
                Kyc
              </a>
            </li>
            <li>
              <a href="/whitepaper.pdf" target="_blank" className="nav-link">
                Whitepaper
              </a>
            </li>
          </ul>
          <div className="action-btns mt-4 ps-3">
            <a
              href="https://www.dextools.io/app/bsc/pair-explorer/0x51a5d5c30551f24014219142b75e1230f964bee9"
              target="_blanks"
              className="btn me-3"
              style={{ background: "#002B84", color: "white" }}
            >
              <i class="fas fa-chart-line"></i>
              <span style={{ marginLeft: "5px" }}>Chart</span>
            </a>
            <a
              href="https://pancakeswap.finance/swap?outputCurrency=0xbba18524CA3c6AA26306E5C402C0FEd72E62A6C3"
              className="btn btn-primary"
              target="_blank"
            >
              Buy Now
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default OffCanvasMenu;
