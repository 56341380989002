import React from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import anim1 from "../../animations/very_profitable.json";
import anim2 from "../../animations/dontmissopp.json";
import SectionTitle from "../common/SectionTitle";

const FeaturedAs = () => {
  return (
    <>
      <section className="integration-section ptb-20 bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-10">
              <SectionTitle title="As Featured In" centerAlign />
            </div>
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="news-list">
              <a
                href="https://www.digitaljournal.com/pr/metaversepay-is-creating-a-new-world-on-metaland"
                target={"_blank"}
              >
                <img src="/assets/img/news/digital-journal.png" />
              </a>
              <a
                href="https://www.wicz.com/story/46699830/metaversepay-is-creating-a-new-world-on-metaland"
                target={"_blank"}
              >
                <img src="/assets/img/news/fox40.png" />
              </a>
              <a
                href="https://fwnbc.marketminute.com/article/getnews-2022-6-16-metaversepay-is-creating-a-new-world-on-metaland"
                target={"_blank"}
              >
                <img src="/assets/img/news/marketminute.png" />
              </a>
              <a
                href="https://www.marketwatch.com/press-release/metaversepay-is-creating-a-new-world-on-metaland-2022-06-16"
                target={"_blank"}
              >
                <img src="/assets/img/news/marketwatch.png" />
              </a>
              <a
                href="https://www.rfdtv.com/story/46699830/metaversepay-is-creating-a-new-world-on-metaland"
                target={"_blank"}
              >
                <img src="/assets/img/news/redtv.png" />
              </a>
              <a
                href="http://business.sherbrookerecord.com/sherbrookerecord/article/getnews-2022-6-16-metaversepay-is-creating-a-new-world-on-metaland/"
                target={"_blank"}
              >
                <img src="/assets/img/news/record.png" />
              </a>
              <a
                href="http://finance.dailyherald.com/dailyherald/article/getnews-2022-6-16-metaversepay-is-creating-a-new-world-on-metaland/"
                target={"_blank"}
              >
                <img src="/assets/img/news/dailyherald.png" />
              </a>
              <a
                href="https://www.wpgxfox28.com/story/46699830/metaversepay-is-creating-a-new-world-on-metaland"
                target={"_blank"}
              >
                <img src="/assets/img/news/fox28.png" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturedAs;
