import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import HeroTitle from "../../components/common/HeroTitle";
import Lottie from "react-lottie";
import hLanding from "../../animations/meta_home.json";

const HeroOne = () => {
  const [isOpen, setOpen] = useState(false);

  const hLandingOptions = {
    loop: true,
    autoplay: true,
    animationData: hLanding,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const copyContract = () => {
    navigator.clipboard.writeText("0xbba18524CA3c6AA26306E5C402C0FEd72E62A6C3");
    document.getElementById("copyButton").innerHTML = '<i class="fas fa-check"/>';
  };
  return (
    <>
      <section className="hero-section ptb-120 text-white bg-gradient" id="home">
        <div className="container">
          <div className="text-center mt-3">
            <h1 className="fw-bold display-5" data-aos="fade-up">
              Are you ready for the{" "}
              <span
                style={{
                  color: "#FF2F00",
                  background: "linear-gradient(to right, #FFCD1D, #FF2F00)",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Metaland
              </span>{" "}
              universe?
            </h1>
            <p
              className="lead"
              data-aos="fade-up"
              data-aos-delay="50"
              style={{ color: "#4475EF", fontWeight: "700" }}
            >
              The Metaland universe is the gateway to the new world. The easiest way to invest in
              this universe is to buy something.
            </p>
          </div>
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-10">
              <div className="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
                <a
                  href="https://metaland.metaversepaybsc.com"
                  target="_blank"
                  className="btn py-3 fw-bolder px-5 col-12 btn-sm-block metaxionBtn mt-3 border-0"
                >
                  Metaland Marketplace Demo
                </a>
                <div
                  className="action-btn mt-3 align-items-center justify-between d-block d-sm-flex d-lg-flex d-md-flex"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <a
                    href="https://pancakeswap.finance/swap?outputCurrency=0xbba18524CA3c6AA26306E5C402C0FEd72E62A6C3"
                    target="_blank"
                    className="btn py-3 fw-bolder px-5 me-3 btn-sm-block buynow"
                    style={{ width: "100%" }}
                  >
                    BUY NOW
                  </a>
                  <div
                    className="text-decoration-none d-inline-flex copy-contract align-items-center watch-now-btn mt-3 mt-lg-0 mt-md-0"
                    style={{ width: "100%" }}
                  >
                    <div className="text-start copy-contract-info text-truncate">
                      <span className="fw-bold text-white fs-6 float-left pull-left">
                        Contract Address
                      </span>
                      <span className="fw-light fs-10 text-truncate contract-address">
                        0xbba18524CA3c6AA26306E5C402C0FEd72E62A6C3
                      </span>
                    </div>
                    <div
                      onClick={() => copyContract()}
                      id="copyButton"
                      className="d-absolute copy-button-container d-flex justify-content-center align-items-center bg-primary"
                    >
                      <i className="fas fa-copy" />
                    </div>
                  </div>
                </div>

                <div
                  className="d-inline-flex social-media  justify-content-end"
                  style={{ width: "100%" }}
                >
                  <div className="my-2">
                    <a href="https://t.me/metaversepaybsc" target="_blank">
                      <img src="assets/img/telegram.png" alt="client" className="img-fluid" />
                    </a>
                  </div>
                  <div className="my-2">
                    <a
                      href="https://poocoin.app/tokens/0xbba18524CA3c6AA26306E5C402C0FEd72E62A6C3"
                      target="_blank"
                    >
                      <img src="assets/img/poocoin.png" alt="client" className="img-fluid" />
                    </a>
                  </div>
                  <div className="my-2">
                    <a href="https://twitter.com/metaversepaybsc" target="_blank">
                      <img src="assets/img/twitter.png" alt="client" className="img-fluid" />
                    </a>
                  </div>
                  <div className="my-2">
                    <a
                      href="https://www.youtube.com/channel/UC3rdZD_LgewmoZgQ_YYXAdw/featured"
                      target="_blank"
                    >
                      <img src="assets/img/youtube.png" alt="client" className="img-fluid" />
                    </a>
                  </div>
                  <div className="my-2">
                    <a href="https://github.com/metaversepaybsc" target="_blank">
                      <img src="assets/img/github.png" alt="client" className="img-fluid" />
                    </a>
                  </div>
                </div>
                <div className="row justify-content-lg-start mt-44 moreinfo-cont">
                  <div className="d-inline-flex justify-content-end">
                    <div className="my-2">
                      <a
                        href="https://bscscan.com/address/0xbba18524CA3c6AA26306E5C402C0FEd72E62A6C3"
                        target="_blank"
                      >
                        <img src="assets/img/bscscan.png" alt="client" className="img-fluid" />
                      </a>
                    </div>
                    {/* <div className='my-2'>
                      <a href="https://nomics.com/assets/metapay2-metapay" target="_blank">
                        <img
                          src='assets/img/nomics.png'
                          alt='client'
                          className='img-fluid'
                        />
                        </a>
                    </div> */}
                    <div className="my-2">
                      <a
                        href="https://coinmarketcap.com/currencies/metaversepay/"
                        target={"_blank"}
                      >
                        <img
                          src="assets/img/coinmarketcap.png"
                          alt="client"
                          className="img-fluid"
                        />
                      </a>
                    </div>
                    <div className="my-2">
                      <a href="https://www.coingecko.com/tr/coins/metaversepay" target={"_blank"}>
                        <img src="assets/img/coingecko.png" alt="client" className="img-fluid" />
                      </a>
                    </div>
                  </div>
                  <div className="d-inline-flex justify-content-end">
                    <div className="my-2">
                      <a
                        href="https://www.dextools.io/app/bsc/pair-explorer/0x51a5d5c30551f24014219142b75e1230f964bee9"
                        target="_blank"
                      >
                        <img src="assets/img/dextools.png" alt="client" className="img-fluid" />
                      </a>
                    </div>
                    <div className="my-2">
                      <a href="https://valid-proof.com" target="_blank">
                        <img src="assets/img/validproof.png" alt="client" className="img-fluid" />
                      </a>
                    </div>
                    <div className="my-2">
                      <a
                        href="https://pancakeswap.finance/swap?outputCurrency=0xbba18524CA3c6AA26306E5C402C0FEd72E62A6C3"
                        target="_blank"
                      >
                        <img src="assets/img/pancakeswap.png" alt="client" className="img-fluid" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 mt-5">
              <div className="hero-img position-relative circle-shape-images">
                {/* <img
                  src='assets/img/home-right.png'
                  alt='hero img'
                  className='img-fluid position-relative z-5'
                /> */}
                <Lottie
                  options={hLandingOptions}
                  style={{ cursor: "default" }}
                  isClickToPauseDisabled={false}
                />
              </div>
            </div>
          </div>
        </div>
        <a
          href="https://t.me/metaversepaybsc"
          target="_blank"
          style={{ position: "fixed", right: "100px", bottom: "50px", zIndex: 90 }}
        >
          <img src={"/telegram.png"} />
        </a>
      </section>
    </>
  );
};

export default HeroOne;
