import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomeSassOne from '../themes/index1/HomeSassOne';
import NotFoundScreen from '../components/others/NotFoundScreen';
import ScrollToTop from '../components/common/ScrollToTop';

class Routes extends Component {
  render() {
    return (
      <>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path='/' component={HomeSassOne} />
            
            <Route component={NotFoundScreen} />
          </Switch>
        </Router>
      </>
    );
  }
}
export default Routes;
