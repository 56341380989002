import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../common/SectionTitle";

const Tokenomics = () => {
  return (
    <>
      <section className="feature-promo ptb-60 bg-white" id="tokenomics">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-6">
              <div class="section-heading text-center" data-aos="fade-up">
                <h4 class="h5 text-primary">How is the distribution made?</h4>
                <h2>Tokenomics</h2>
                <p>
                  If you are wondering about the distribution amounts in MetaversePay, you can
                  examine tokenomics immediately.
                  <span style={{ fontWeight: "bold" }}>
                    Buy tax 6% (marketing 5% and re-distribution 1%), sell tax 6% (5% marketing and
                    1% re-distribution).
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="text-center">
              <img
                className="mx-auto tokenomicsImg"
                style={{ maxWidth: "70%" }}
                src={"assets/img/tokenomics.png"}
              />
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-feature-promo p-lg-5 p-3 text-center mt-4">
                <div className="feature-icon icon-center">
                  {/* <i className='fal fa-layer-group fa-2x text-white'></i> */}
                  <img src={"assets/img/heart.png"} className="img-fluid" />
                </div>
                <div className="feature-info-wrap">
                  <h3 className="h5">Burn</h3>
                  <p>We multiply our value with the total amount of tokens burned.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-feature-promo p-lg-5 p-3 text-center mt-4">
                <div className="feature-icon icon-center">
                  <img src={"assets/img/puzzle.png"} className="img-fluid" />
                </div>
                <div className="feature-info-wrap">
                  <h3 className="h5">Community</h3>
                  <p>The amount of tokens reserved for the community.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-feature-promo p-lg-5 p-3 text-center mt-4">
                <div className="feature-icon icon-center">
                  <img src={"assets/img/announcement.png"} className="img-fluid" />
                </div>
                <div className="feature-info-wrap">
                  <h3 className="h5">Marketing & Team</h3>
                  <p>Total marketing and team tokens to be spent promoting MetaversePay.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tokenomics;
