import React from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import anim1 from "../../animations/work.json";
import anim2 from "../../animations/meta_glasses.json";
import anim3 from "../../animations/run.json";

const FeatureImg = () => {
  const anim1Options = {
    loop: true,
    autoplay: true,
    animationData: anim1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const anim2Options = {
    loop: true,
    autoplay: true,
    animationData: anim2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const anim3Options = {
    loop: true,
    autoplay: true,
    animationData: anim3,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <section className="image-feature pt-60 pb-120">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-5 col-12 order-lg-1">
              <div className="feature-img-content">
                <div className="icon-box d-inline-block">
                  {/* <i className='fal fa-fingerprint fa-2x text-white'></i> */}
                  <img style={{ height: "70px" }} src={"assets/img/fire.png"} />
                </div>
                <h2>Buy your land in Metaland.</h2>
                <p>
                  We prepared This brand new and limitless universe offers incredible opportunities
                  for everyone. Do not miss the opportunities for the working environment and
                  investments where you can earn endless income.
                </p>
                <p>
                  The Metaland universe and the new developed world are preparing for you. Book your
                  place now.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-0">
              <div className="feature-img-holder mt-4 mt-lg-0 mt-xl-0">
                {/* <img
                  src='assets/img/buyyourplace.png'
                  className='img-fluid'
                  alt='feature-img'
                /> */}
                <Lottie options={anim1Options} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-us pb-120">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-5 col-12">
              <div className="feature-img-content">
                <div className="icon-box d-inline-block">
                  {/* <i className='fal fa-fingerprint fa-2x text-white'></i> */}
                  <img style={{ height: "70px" }} src={"assets/img/mountain.png"} />
                </div>
                <h2>Easy shopping and payment method</h2>
                <p>
                  If you wish, you can then earn MetaversePay tokens by selling the purchased land
                  to others, and you can exchange the MetaversePay’s you earn for any cryptocurrency
                  of your choice.
                </p>
                <p>
                  With MetaversePay, you can own a lot of things in the Metaland universe. Release
                  your chains and dive into this universe.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="feature-img-holder mt-4 mt-lg-0 mt-xl-0">
                {/* <img
                  src='assets/img/universalpayment.png'
                  className='img-fluid'
                  alt='feature-img'
                /> */}
                <Lottie options={anim2Options} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="image-feature pt-60 pb-120">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-5 col-12 order-lg-1">
              <div className="feature-img-content">
                <div className="icon-box d-inline-block">
                  {/* <i className='fal fa-fingerprint fa-2x text-white'></i> */}
                  <img style={{ height: "70px" }} src={"assets/img/chart.png"} />
                </div>
                <h2>Meet the easiest payment method in the universe.</h2>
                <p>
                  All of the problems we experience are related to payment methods. With
                  MetaversePay, we completely eliminate this problem, everything becomes simpler.
                </p>
                <p>
                  Our payment and integrated system is valid in the Metaland universe and completely
                  easy. Come on, join us before you miss this train.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-0">
              <div className="feature-img-holder mt-4 mt-lg-0 mt-xl-0">
                {/* <img
                  src='assets/img/vrsection.png'
                  className='img-fluid'
                  alt='feature-img'
                /> */}
                <Lottie options={anim3Options} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImg;
