import React from "react";
import { FaqOneData } from "../../data";
import SectionTitle from "../common/SectionTitle";
import Lottie from "react-lottie";
import anim1 from "../../animations/faq.json";

const FaqOne = () => {
  const anim1Options = {
    loop: true,
    autoplay: true,
    animationData: anim1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <section className="faq-section ptb-100 bg-light" id="faq">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-6">
              <SectionTitle
                subtitle="All the answers you are looking for are here."
                title="Frequently Asked Questions"
                description="If you want to take your place in the Metaland, learn the path to follow and how to use the payment method."
                centerAlign
              />
            </div>
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-5 col-12">
              <div className="faq-wrapper">
                {FaqOneData.map((data) => (
                  <div className="faq-item mb-5" key={data.id}>
                    <h5>
                      <span className="h3 text-primary me-2">Q.</span>
                      {data.faqTitle}
                    </h5>
                    <p>{data.faqDesc}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-center mt-4 mt-lg-0 mt-md-0">
                {/* <img src='assets/img/faqright.png' alt='faq' className='img-fluid' /> */}
                <Lottie options={anim1Options} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqOne;
