import React from "react";
import SectionTitle from "../common/SectionTitle";
import Lottie from "react-lottie";
import anim1 from "../../animations/man_phone.json";
import anim2 from "../../animations/man_hi.json";

const WorkProcess = () => {
  const anim1Options = {
    loop: true,
    autoplay: true,
    animationData: anim1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const anim2Options = {
    loop: true,
    autoplay: true,
    animationData: anim2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <section className="work-process ptb-120" id="roadmap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-10">
              <SectionTitle
                subtitle="Are you ready?"
                title="Roadmap"
                description="We have determined our roadmap for MetaversePay. We convey all the details to you in the simplest form."
                centerAlign
              />
            </div>
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-5 col-md-12 order-1 order-lg-0">
              <div className="img-wrap">
                {/* <img
                  src='assets/img/road1.png'
                  alt='work process'
                  className='img-fluid rounded-custom'
                /> */}
                <Lottie options={anim1Options} />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 order-0 order-lg-1">
              <ul className="work-process-list list-unstyled">
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/completed.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 1</span>
                    <h3 className="h5 mb-2">Website Launch</h3>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/completed.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 1</span>
                    <h3 className="h5 mb-2">Social Media Launch</h3>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/completed.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 1</span>
                    <h3 className="h5 mb-2">Metaland Universe Demo Launch</h3>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/completed.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 1</span>
                    <h3 className="h5 mb-2">Burn 20% METAVERSEPAY</h3>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/completed.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 1</span>
                    <h3 className="h5 mb-2">Audit</h3>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/completed.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 1</span>
                    <h3 className="h5 mb-2">KYC</h3>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/completed.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 1</span>
                    <h3 className="h5 mb-2">Marketing Campaigns</h3>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/completed.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 1</span>
                    <h3 className="h5 mb-2">Fairlaunch</h3>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/completed.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 2</span>
                    <h3 className="h5 mb-2">Centeralized Exchange</h3>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="row align-items-center justify-content-between mt-5">
            <div className="col-lg-5 col-md-12 order-1 order-lg-1">
              <div className="img-wrap">
                {/* <img
                  src='assets/img/road2.png'
                  alt='work process'
                  className='img-fluid rounded-custom'
                /> */}
                <Lottie options={anim2Options} />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 order-0 order-lg-0">
              <ul className="work-process-list list-unstyled">
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/completed.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 2</span>
                    <h3 className="h5 mb-2">CoinMarketCap Listing</h3>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/completed.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 2</span>
                    <h3 className="h5 mb-2">CoinGecko Listing</h3>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/uncompleted.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 2</span>
                    <h3 className="h5 mb-2">BscScan Update</h3>
                  </div>
                </li>

                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/uncompleted.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 2</span>
                    <h3 className="h5 mb-2">Metaland Avatars Demo Launch</h3>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/uncompleted.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 3</span>
                    <h3 className="h5 mb-2">Mobile Application (iOS & Android)</h3>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/uncompleted.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 3</span>
                    <h3 className="h5 mb-2">Influencer Marketing Campaigns</h3>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/uncompleted.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 3</span>
                    <h3 className="h5 mb-2">Metaland Avatars Launch</h3>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/uncompleted.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 4</span>
                    <h3 className="h5 mb-2">Metaland Universe Launch</h3>
                  </div>
                </li>
                <li className="d-flex align-items-start mb-4">
                  <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                    <img src={"assets/img/uncompleted.png"} style={{ height: "60px" }} />
                  </div>
                  <div className="icon-content">
                    <span className="text-primary h6">Phase 4</span>
                    <h3 className="h5 mb-2">Big Marketing Campaigns</h3>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkProcess;
