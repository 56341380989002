import React, { Component } from "react";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import HeroOne from "./HeroOne";
import FeatureImgContentFour from "../../components/feature-img-content/FeatureImgContentFour";
import FeatureImg from "../../components/features/FeatureImg";
import PageMeta from "../../components/common/PageMeta";
import TestimonialOne from "../../components/testimonial/TestimonialOne";
import WorkProcess from "../../components/work-process/WorkProcess";
import PriceOne from "../../components/prices/PriceOne";
import FaqOne from "../../components/faqs/FaqOne";
import IntegrationOne from "../../components/integration/IntegrationOne";
import CtaOne from "../../components/cta/CtaOne";
import FooterOne from "../../layout/Footer/FooterOne";
import FeatureOne from "../../components/features/FeatureOne";
import Tokenomics from "../../components/features/Tokenomics";
import FeaturedAs from "../../components/integration/FeaturedAs";

class HomeSassOne extends Component {
  render() {
    return (
      <Layout>
        <PageMeta title="MetaversePay" />
        <Navbar navDark />
        <HeroOne />
        <FeatureImg />
        <TestimonialOne darkBg />
        <Tokenomics />
        <FeatureOne />
        {/* <FeatureImgContentFour /> */}

        <WorkProcess />
        <IntegrationOne />
        <FeaturedAs />
        <FaqOne />

        <CtaOne />
        <FooterOne footerGradient />
      </Layout>
    );
  }
}

export default HomeSassOne;
