import React, { Component } from "react";
import SectionTitle from "../common/SectionTitle";
import TestimonialModal from "./TestimonialModal";
import Rating from "../review/Rating";
import Lottie from "react-lottie";
import anim1 from "../../animations/computer.json";

class TestimonialOne extends Component {
  render() {
    const { darkBg } = this.props;
    const anim1Options = {
      loop: true,
      autoplay: true,
      animationData: anim1,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <>
        <section
          className={`customer-review-tab ptb-120 ${
            darkBg ? "bg-gradient text-white" : "bg-light"
          } position-relative z-2`}
        >
          <div className="container">
            <div className="row justify-content-center align-content-center">
              <div className="col-md-10 col-lg-8">
                {darkBg ? (
                  <div class="section-heading text-center" data-aos="fade-up">
                    <h4 class="h5 text-success">Future? Now.</h4>
                    <h2>
                      The future of the <span className="gra-text">Metaland</span> universe!
                    </h2>
                    <p>
                      We will be adopting new and different payment methods in the coming years. We
                      continue to work to be the best among them.
                    </p>
                  </div>
                ) : (
                  <SectionTitle
                    subtitle="Future? Now."
                    title="The future of the universe!"
                    description="In the coming years, we will have adopted a very different lifestyle. We continue to work on this situation."
                    centerAlign
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="tab-content" id="testimonial-tabContent">
                  <div className="tab-pane fade active show" id="testimonial-tab-1" role="tabpanel">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-lg-6 col-md-6">
                        <div className="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                          <img
                            src="assets/img/rocket.png"
                            alt="testimonial quote"
                            width="90"
                            className="img-fluid mb-24"
                          />
                          <div className="blockquote-title-review mb-4">
                            <h3 className="mb-0 h4 fw-semi-bold">A new future is being built.</h3>
                            <Rating />
                          </div>
                          <blockquote className="blockquote">
                            <p>
                              From the beginning, we develop and develop for those who want to be
                              one of the best in this universe. Keeping all the steps simple is
                              always our priority.
                            </p>
                            <p>
                              At this stage, you will be able to control data, objects, fields or
                              everything else in projects with a single payment method.
                            </p>
                          </blockquote>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-6">
                        <div className="author-img-wrap pt-5 ps-5">
                          <div className=" position-relative">
                            {/* <img
                              src='assets/img/futureright.png'
                              className='img-fluid'
                              alt='testimonial author'
                            /> */}
                            <Lottie options={anim1Options} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default TestimonialOne;
