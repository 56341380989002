import React from "react";
import { Link } from "react-router-dom";

const FooterOne = ({ footerLight, style, footerGradient }) => {
  return (
    <>
      <footer className="footer-section" style={{ position: "relative" }}>
        <div
          className={`footer-top ${footerLight ? "footer-light" : "bg-dark"} ${
            footerGradient ? "bg-gradient" : ""
          }  text-white ptb-60`}
          style={style}
        >
          <div className="container" style={{ zIndex: 9 }}>
            <div className="row justify-content-between">
              <div className="col-md-8 col-lg-4 mb-md-4 mb-lg-0">
                <div className="footer-single-col">
                  <div className="footer-single-col mb-4">
                    <img
                      src="assets/img/logo.png"
                      alt="logo"
                      className="img-fluid logo-white"
                      style={{ height: "28px" }}
                    />
                    <img src="assets/img/logo.png" alt="logo" className="img-fluid logo-color" />
                  </div>
                  <p style={{ color: "white", maxWidth: "350px" }}>
                    The safe and only valid payment method for the Metaland universe.
                  </p>

                  <p className="mt-5" style={{ color: "white", maxWidth: "260px" }}>
                    2022 MetaversePay. Future Now.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0">
                <div className="row">
                  <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        <li>
                          <a href="/#home" className="text-decoration-none">
                            Home
                          </a>
                        </li>
                        <li>
                          <a href="/#about" className="text-decoration-none">
                            About
                          </a>
                        </li>
                        <li>
                          <a
                            href="/whitepaper.pdf"
                            target="_blank"
                            className="text-decoration-none"
                          >
                            Whitepaper
                          </a>
                        </li>
                        <li>
                          <a href="/#howtobuy" className="text-decoration-none">
                            How To Buy?
                          </a>
                        </li>
                        <li>
                          <a href="/#faq" className="text-decoration-none">
                            Faq
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        <li>
                          <a
                            href="https://github.com/valid-proof/audit/blob/main/ValidProof_SmartContract_Audit_Metaversepay.pdf"
                            target="_blank"
                            className="text-decoration-none"
                          >
                            Audit
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://github.com/valid-proof/kyc/blob/main/ValidProof_KYC_Certificate_Metaversepay.pdf"
                            target="_blank"
                            className="text-decoration-none"
                          >
                            Kyc
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://dxsale.app/app/v3_3/dxlockview?id=0&add=0x51a5D5c30551F24014219142b75E1230F964BeE9&type=lplock&chain=BSC"
                            target="_blank"
                            className="text-decoration-none"
                          >
                            Liquidity Locker
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://github.com/metaversepaybsc"
                            target="_blank"
                            className="text-decoration-none"
                          >
                            Github
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        <li>
                          <a
                            href="https://pancakeswap.finance/swap?outputCurrency=0xbba18524CA3c6AA26306E5C402C0FEd72E62A6C3"
                            className="text-decoration-none"
                            target="_blank"
                          >
                            PancakeSwap
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://poocoin.app/tokens/0xbba18524CA3c6AA26306E5C402C0FEd72E62A6C3"
                            className="text-decoration-none"
                            target="_blank"
                          >
                            Poocoin
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://bscscan.com/address/0xbba18524CA3c6AA26306E5C402C0FEd72E62A6C3"
                            className="text-decoration-none"
                            target="_blank"
                          >
                            Bscscan
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.dextools.io/app/bsc/pair-explorer/0x51a5d5c30551f24014219142b75e1230f964bee9"
                            className="text-decoration-none"
                            target="_blank"
                          >
                            Dextools
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://valid-proof.com"
                            className="text-decoration-none"
                            target="_blank"
                          >
                            ValidProof
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
