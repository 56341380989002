import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import SectionTitle from "../common/SectionTitle";

const CtaOne = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section className="cta-subscribe pb-120" id="howtobuy">
        <div className="container">
          <div className="bg-gradient ptb-60 position-relative overflow-hidden rounded-custom">
            <div className="row">
              <div className="col-lg-7 col-md-8">
                <div
                  className="subscribe-info-wrap position-relative z-2"
                  style={{ paddingLeft: "3rem" }}
                >
                  <SectionTitle
                    title="How to buy MetaversePay?"
                    description="We've simplified the process and made a detailed video for easy purchase! Take a look right now!"
                    dark
                  />

                  <div className="form-block-banner mw-60 mt-5">
                    <a
                      href="https://pancakeswap.finance/swap?outputCurrency=0xbba18524CA3c6AA26306E5C402C0FEd72E62A6C3"
                      target="_blank"
                      className="btn btn-primary"
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-4 col-xs-12">
                <div
                  onClick={() => setOpen(true)}
                  style={{ position: "relative", cursor: "pointer" }}
                >
                  <img
                    className="howtobuyimage"
                    src={"assets/img/howtobuyimage.png"}
                    style={{ height: "300px" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <ModalVideo
            channel="youtube"
            isOpen={isOpen}
            videoId="iG0O6GLxwrk"
            onClose={() => setOpen(false)}
          />
        </div>
      </section>
    </>
  );
};

export default CtaOne;
