import React from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import anim1 from "../../animations/very_profitable.json";
import anim2 from "../../animations/dontmissopp.json";

const IntegrationOne = () => {
  const anim1Options = {
    loop: true,
    autoplay: true,
    animationData: anim1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const anim2Options = {
    loop: true,
    autoplay: true,
    animationData: anim2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <section className="integration-section ptb-120 bg-light">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-3">
              <div className="integration-list-wrap">
                {/* <a
                  href="/#"
                  className="integration-1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Metapay"
                >
                  <img src="assets/img/goldmeta.png" alt="integration" className="img-fluid" />
                </a>
                <a
                  href="/#"
                  className="integration-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Metapay"
                >
                  <img
                    src="assets/img/bronzemeta.png"
                    alt="integration"
                    className="img-fluid rounded-circle"
                  />
                </a>
                <a
                  href="/#"
                  className="integration-3"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Metapay"
                >
                  <img src="assets/img/bronzemeta.png" alt="integration" className="img-fluid" />
                </a>

                <a
                  href="/#"
                  className="integration-4"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Metapay"
                >
                  <img src="assets/img/bronzemeta.png" alt="integration" className="img-fluid" />
                </a>
                <a
                  href="/#"
                  className="integration-5"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Metapay"
                >
                  <img src="assets/img/goldmeta.png" alt="integration" className="img-fluid" />
                </a>
                <a
                  href="/#"
                  className="integration-6"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Metapay"
                >
                  <img
                    src="assets/img/bronzemeta.png"
                    alt="integration"
                    className="img-fluid rounded-circle"
                  />
                </a> */}
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="section-heading text-center my-5 my-lg-0 my-xl-0">
                <h4 className="text-primary h5">Valid in the Metaland Universe</h4>
                <h2>Discover all the places you can spend with MetaversePay.</h2>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="col-lg-4">
                <div className="integration-list-wrap">
                  {/* <a
                    href="/#"
                    className="integration-7"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Metapay"
                  >
                    <img src="assets/img/bronzemeta.png" alt="integration" className="img-fluid" />
                  </a>
                  <a
                    href="/#"
                    className="integration-8"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Metapay"
                  >
                    <img
                      src="assets/img/goldmeta.png"
                      alt="integration"
                      className="img-fluid rounded-circle"
                    />
                  </a>
                  <a
                    href="/#"
                    className="integration-9"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Metapay"
                  >
                    <img
                      src="assets/img/bronzemeta.png"
                      alt="integration"
                      className="img-fluid rounded-circle"
                    />
                  </a>

                  <a
                    href="/#"
                    className="integration-10"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Metapay"
                  >
                    <img
                      src="assets/img/bronzemeta.png"
                      alt="integration"
                      className="img-fluid rounded-circle"
                    />
                  </a>
                  <a
                    href="/#"
                    className="integration-11"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Metapay"
                  >
                    <img
                      src="assets/img/goldmeta.png"
                      alt="integration"
                      className="img-fluid rounded-circle"
                    />
                  </a>
                  <a
                    href="/#"
                    className="integration-12"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Metapay"
                  >
                    <img
                      src="assets/img/bronzemeta.png"
                      alt="integration"
                      className="img-fluid rounded-circle"
                    />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-100">
            <div className="col-lg-6 mt-4">
              <div className="position-relative d-flex flex-column h-100 flex-wrap bg-white p-5 rounded-custom">
                <div className="cta-left-info mb-2">
                  <h5 className="gra-text">Profitable and Universal</h5>
                  <p>
                    Take your place in the unpredictable Metalland universe and start your
                    investments right away.
                  </p>
                </div>
                <div className="cta-img position-absolute right-0 bottom-0">
                  {/* <img
                      src='assets/img/profiletableuniversal.png'
                      alt='cta'
                      style={{height: "180px"}}
                      className='img-fluid'
                    /> */}
                  <Lottie options={anim1Options} />
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-4">
              <div className="position-relative d-flex flex-column h-100 flex-wrap bg-white p-5 rounded-custom">
                <div className="cta-left-info mb-2">
                  <h5 className="gra-text">Don't miss the opportunities.</h5>
                  <p>
                    Get your MetaversePay token now and get priority in purchasing in the Metaland
                    universe.
                  </p>
                </div>
                <div className="cta-img position-absolute right-0 bottom-0">
                  {/* <img
                      src='assets/img/opportunities.png'
                      alt='cta'
                      style={{height: "180px"}}
                      className='img-fluid'
                    /> */}
                  <Lottie options={anim2Options} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntegrationOne;
