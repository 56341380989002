import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../common/SectionTitle";
import Lottie from "react-lottie";
import everywhereAnim from "../../animations/everywhere.json";
import savetimeAnim from "../../animations/savetime.json";
import stayconnectedAnim from "../../animations/stayconnected.json";
import pwMetaAnim from "../../animations/man_01.json";
import startEnjoyAnim from "../../animations/brain.json";

const FeatureOne = () => {
  const everywhereOptions = {
    loop: true,
    autoplay: true,
    animationData: everywhereAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const saveTimeOptions = {
    loop: true,
    autoplay: true,
    animationData: savetimeAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const stayConnectedOptions = {
    loop: true,
    autoplay: true,
    animationData: stayconnectedAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const pwMetaOptions = {
    loop: true,
    autoplay: true,
    animationData: pwMetaAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const startEnjoyOptions = {
    loop: true,
    autoplay: true,
    animationData: startEnjoyAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <section className="feature-promo ptb-120 bg-light" id={"about"}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div class="section-heading text-center" data-aos="fade-up">
                <h2>
                  What can we do in this <span className="gra-text">Metaland</span> universe?
                </h2>
                <p>
                  The free and untraceable system created by the meta universe encouraged us to
                  develop a new payment system. This method is called MetaversePay! You can do your
                  shopping freely with this payment system, which will only be valid in the
                  Metalland universe.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-feature-promo p-lg-5 p-3 text-center mt-4">
                <div className="feature-icon icon-center">
                  {/* <i className='fal fa-layer-group fa-2x text-white'></i> */}
                  {/* <img src={"assets/img/everywhere.png"} className="img-fluid" /> */}
                  <Lottie options={everywhereOptions} />
                </div>
                <div className="feature-info-wrap">
                  <h3 className="h5">Everywhere</h3>
                  <p>
                    A world without limits means new opportunities for us. A decentralized payment
                    method valid in the Metalland universe.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-feature-promo p-lg-5 p-3 text-center mt-4">
                <div className="feature-icon icon-center">
                  {/* <img src={"assets/img/savetime.png"} className="img-fluid" /> */}
                  <Lottie options={saveTimeOptions} />
                </div>
                <div className="feature-info-wrap">
                  <h3 className="h5">Save Time</h3>
                  <p>
                    MetaversePay is an easy-to-use payment method. All you have to do is have the
                    MetaversePay token.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-feature-promo p-lg-5 p-3 text-center mt-4">
                <div className="feature-icon icon-center">
                  {/* <img src={"assets/img/stayconnected.png"} className="img-fluid" /> */}
                  <Lottie options={stayConnectedOptions} />
                </div>
                <div className="feature-info-wrap">
                  <h3 className="h5">Stay Connected</h3>
                  <p>
                    A link in a chain. A single blockchain connected to the meta universe and valid
                    everywhere.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-60">
            <div className="col-lg-6 mt-4">
              <div className="position-relative d-flex flex-column h-100 flex-wrap bg-white p-5 rounded-custom">
                <div className="cta-left-info mb-2">
                  <h5 className="gra-text">Pay with MetaversePay.</h5>
                  <p>
                    Looking to buy a plot or avatar in the Metalland universe? All you have to do is
                    have the MetaversePay token.
                  </p>
                </div>
                <div className="cta-img position-absolute right-0 bottom-0">
                  {/* <img
                    src='assets/img/paywithmeta.png'
                    alt='cta'
                    style={{height: "250px"}}
                    className='img-fluid'
                  /> */}
                  <Lottie options={pwMetaOptions} />
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-4">
              <div className="position-relative d-flex flex-column h-100 flex-wrap bg-white p-5 rounded-custom">
                <div className="cta-left-info mb-2">
                  <h5 className="gra-text">Start Enjoying!</h5>
                  <p>
                    Don't waste your time. We strive to maximize your enjoyment of our great
                    content.
                  </p>
                </div>
                <div className="cta-img position-absolute right-0 bottom-0">
                  {/* <img
                    src='assets/img/startenjoying.png'
                    alt='cta img'
                    style={{height: "250px"}}
                    className='img-fluid'
                  /> */}
                  <Lottie options={startEnjoyOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureOne;
